
import { getTranslationsContexts, saveTranslationsContext } from '@/api/translations.api'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { IContext } from '@/models/translations/TranslationContext'
import { CMSRouteNames } from '@/router/route-names'
import { useToast } from 'primevue/usetoast'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'TranslationsHome',
  components: {
  },
  setup () {
    const toast = useToast()
    const router = useRouter()
    const contexts = ref<Array<IContext>>([])
    const loadingContexts = ref<boolean>(true)
    const contextEditDialogVisibility = ref<boolean>(false)
    const getEmptyContext: () => Partial<IContext> = () => ({
      key: '',
      name: ''
    })
    const editingContext = ref<Partial<IContext>>(getEmptyContext())
    const isValidContextData = computed(() =>
      Boolean(
        editingContext.value.name?.trim() && editingContext.value.key?.trim()
      )
    )

    onMounted(async () => {
      try {
        contexts.value = await getTranslationsContexts()

        loadingContexts.value = false
      } catch (error) {
        toast.add({
          summary: ToastSummaries.Error,
          detail: 'No se pudo obtener los contextos de traducción',
          severity: ToastSeverities.Error,
          life: ToastLife.Default
        })
      } finally {
        loadingContexts.value = false
      }
    })

    const onEdit = (context: IContext) => {
      router.push({ name: CMSRouteNames.TRANSLATIONS_EDIT, params: { context: context.key } })
    }

    const onEditContext = async (context: IContext | null) => {
      editingContext.value = (context && { ...context }) || getEmptyContext()
      contextEditDialogVisibility.value = true
    }

    const onSaveContext = async () => {
      try {
        loadingContexts.value = true
        await saveTranslationsContext(editingContext.value)
        contexts.value = await getTranslationsContexts()
        toast.add({
          summary: ToastSummaries.Success,
          detail: 'Contexto de traducción guardado',
          severity: ToastSeverities.Success,
          life: ToastLife.Default
        })
        contextEditDialogVisibility.value = false
      } catch (error) {
        toast.add({
          summary: ToastSummaries.Error,
          detail: 'No se pudo guardar el contexto de traducción: ' + (error instanceof Error ? error.message : error),
          severity: ToastSeverities.Error,
          life: ToastLife.Default
        })
      } finally {
        loadingContexts.value = false
      }
    }

    return {
      contexts,
      loadingContexts,
      contextEditDialogVisibility,
      editingContext,
      onEditContext,
      onSaveContext,
      isValidContextData,
      onEdit
    }
  }
})
